// Layouts
import '@styles/layouts/l-image-gallery.scss';

// Components
import '@styles/components/c-image-gallery.scss';
import '@styles/components/c-modal.scss';
import '@styles/overrides/lightgallery.scss';

// Entry Point specific ES Modules
import imageGallery from '@modules/imageGallery';

// Initiate Entry Point specific ES Modules
imageGallery();
