/* global Cookiebot */
import { pauseVideo } from '@modules/videoControls';
import inView from '@modules/inView';

// Load swiper styles with dynamic import so will not be imported in the same entry point twice
// (also used in another component)
const getCarouselStyles = () => {
  import('swiper/css');
  import('swiper/css/navigation');
}

const getLightGalleryStyles = () => {
  import('lightgallery/css/lightgallery.css');
  import('lightgallery/css/lg-zoom.css');
}

export default function init() {
  const imageGalleries = document.querySelectorAll('.js-image-gallery');

  inView(imageGalleries, async () => {
    getCarouselStyles();

    const [
      { default: Swiper, Navigation },
      { default: lightGallery },
      { default: lgVideo },
      { default: lgZoom },
    ] = await Promise.all([
      import('swiper'),
      import('lightgallery'),
      import('lightgallery/plugins/video'),
      import('lightgallery/plugins/zoom'),
    ]);

    imageGalleries.forEach(($el) => {
      const $countEl = $el.querySelector('.js-carousel-count');

      // Init slider
      const swiper = new Swiper($el.querySelector('.swiper'), {
        modules: [Navigation],
        loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        navigation: {
          nextEl: $el.querySelector('.swiper-button-next'),
          prevEl: $el.querySelector('.swiper-button-prev'),
        },
        breakpoints: {
          568: {
            slidesPerView: 1.5,
            slideToClickedSlide: true,
          },
        },
        on: {
          init() {
            const slides = $el.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)').length;
            $countEl.textContent = `1 of ${slides}`;
          },
          slideChange(sw) {
            const slides = $el.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)').length;
            $countEl.textContent = `${sw.realIndex + 1} of ${slides}`;
          },
        },
      });

      /* pause video in iframe when changing slide  */
      swiper.on('slideChange', () => {
        const $currentSlideWithIframe = $el.querySelector('.swiper-slide-active iframe');
        if (!$currentSlideWithIframe) return;
        pauseVideo($currentSlideWithIframe, 'youtube');
      });

      // Query $lightGallery element
      const $lightGallery = $el.querySelector('.swiper-wrapper');

      // Hide light gallery container with JS as we dont have styles yet to do that
      let selector;
      $lightGallery.addEventListener('lgInit', (event) => {
        // Save selector to use in later event
        selector = event.detail.instance.$container.selector;
        // Hide the container
        selector.style.display = 'none';
      });

      // Load styles just before opening light gallery
      $lightGallery.addEventListener('lgBeforeOpen', () => {
        // Remove the inline style hiding the light gallery container
        if (selector) {
          selector.removeAttribute('style');
        }
        // Load the light gallery styles
        getLightGalleryStyles();
      });

      // Add lightbox
      const selectorWithVideos = '.swiper-slide:not(.swiper-slide-duplicate) .js-image-gallery__lightbox-slide';
      const galleryOpts = {
        licenseKey: 'E98496B8-C2DA4C50-B5800A51-C4FC70D0',
        selector: selectorWithVideos,
        plugins: [lgZoom, lgVideo],
        download: false,
        showZoomInOutIcons: true,
        actualSize: false,
        prevHtml: '<span class="lg-custom-button-prev" aria-label="Previous"><svg class="lg-custom-button-icon" aria-hidden="true" focusable="false" viewBox="0 0 46 38" xmlns="http://www.w3.org/2000/svg"><path d="M15.975 1L45 1V37H15.975L2 19.5143L15.975 1Z" stroke-width="2" fill="currentColor"></path><path class="arrow-inner" d="M17.2929 19.7071C16.9024 19.3166 16.9024 18.6834 17.2929 18.2929L23.6569 11.9289C24.0474 11.5384 24.6805 11.5384 25.0711 11.9289C25.4616 12.3195 25.4616 12.9526 25.0711 13.3431L19.4142 19L25.0711 24.6569C25.4616 25.0474 25.4616 25.6805 25.0711 26.0711C24.6805 26.4616 24.0474 26.4616 23.6569 26.0711L17.2929 19.7071ZM37 20H18V18H37V20Z" stroke-width="0"></path></svg></span>',
        nextHtml: '<span class="lg-custom-button-next" aria-label="Next"><svg class="lg-custom-button-icon" aria-hidden="true" focusable="false" viewBox="0 0 46 38" xmlns="http://www.w3.org/2000/svg"><path d="M15.975 1L45 1V37H15.975L2 19.5143L15.975 1Z" stroke-width="2" fill="currentColor"></path><path class="arrow-inner" d="M17.2929 19.7071C16.9024 19.3166 16.9024 18.6834 17.2929 18.2929L23.6569 11.9289C24.0474 11.5384 24.6805 11.5384 25.0711 11.9289C25.4616 12.3195 25.4616 12.9526 25.0711 13.3431L19.4142 19L25.0711 24.6569C25.4616 25.0474 25.4616 25.6805 25.0711 26.0711C24.6805 26.4616 24.0474 26.4616 23.6569 26.0711L17.2929 19.7071ZM37 20H18V18H37V20Z" stroke-width="0"></path></svg></span>',
        getCaptionFromTitleOrAlt: false, // Instead, we want to use a dedicated caption field value
        mobileSettings: {
          controls: false,
          showCloseIcon: true,
          download: false,
        },
      };

      // Skip iframes if consent not yet given
      if (window.Cookiebot
        // window.Cookiebot may exist as an HTML element
        && (!window.Cookiebot.consent || !window.Cookiebot.consent.marketing)
        && $lightGallery.querySelector('.js-image-gallery__lightbox-slide--iframe')
      ) {
        galleryOpts.selector += ':not(.js-image-gallery__lightbox-slide--iframe)';

        // Wait for the cookies response
        const enableVideos = () => {
          // Currently only supports marketing consent
          if (Cookiebot.consent.marketing) {
            galleryOpts.selector = selectorWithVideos;
            galleryInstance.destroy();
            galleryInstance = lightGallery($lightGallery, galleryOpts);
            window.removeEventListener('CookiebotOnAccept', enableVideos);
          }
        };
        window.addEventListener('CookiebotOnAccept', enableVideos);
      }

      let galleryInstance = lightGallery($lightGallery, galleryOpts);
    });
  });
}
